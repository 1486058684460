import { createApp } from 'vue'

import SeasonTicketRegistration from './components/SeasonTicketRegistration'
import FontAwesomeIcon from './components/FontawesomeIcon'

document.querySelectorAll('.season-ticket-registration')
    .forEach(container => createApp()
        .component('SeasonTicketRegistration', SeasonTicketRegistration)
        .component('FontAwesomeIcon', FontAwesomeIcon)
        .mount(container)
    )
