import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faPlus,
    faMinus,
    faTimes,
    faTrash,
    faSpinner,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCalendar,
    faCamera,
    faUpload,
    faImage,
    faFloppyDisk
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add([
    faPlus,
    faMinus,
    faTimes,
    faTrash,
    faSpinner,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCalendar,
    faCamera,
    faUpload,
    faImage,
    faFloppyDisk
])

export default FontAwesomeIcon