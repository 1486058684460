<script setup>
    import { ref, defineProps, onMounted } from 'vue'
    import ImageUpload from './ImageUpload.vue'
    import { Modal } from 'bootstrap'

    const props = defineProps({
        action: {
            type: String,
            required: true,
        },

        hideAlert: {
            type: Boolean,
            required: false,
            default: false,
        },

        onlyAllowCamera: {
            type: Boolean,
            required: false,
            default: false,
        },

        redirect: {
            type: String,
            required: false,
            default: null,
        },

        form: {
            type: Object,
            required: false,
            default: () => ({})
        },
    })

    const form = ref(false)

    const confirmationModal = ref(null)
    const error = ref(false)
    const image = ref(null)
    const firstname = ref(null)
    const surname = ref(null)
    const email = ref(null)
    const busy = ref(false)
    const modal = ref(null)

    onMounted(() => {
        if (props.form) {
            firstname.value = props.form.firstname ?? null
            surname.value = props.form.surname ?? null
            email.value = props.form.email ?? null
            image.value = props.form.image ?? null
        }
    })

    const showConfirmationModal = () => {
        if (!modal.value) {
            modal.value = new Modal(confirmationModal.value)
        }

        modal.value.show()
    }

    const submitForm = async () => {
        if (modal.value) {
            modal.value.hide()
        }

        error.value = false
        busy.value = true

        try {
            const request = await fetch(props.action, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    image: image.value,
                    firstname: firstname.value,
                    surname: surname.value,
                    email: email.value,
                }),
            })

            if (request.status < 200 || request.status >= 300) {
                throw new Error('')
            }

            if (props.redirect) {
                window.location.href = props.redirect
            } else {
                const response = await request.json()
                const { next } = response
                window.location.href = next
            }
        } catch (e) {
            error.value = true
            busy.value = false
        } 
    }

    const onSubmit = async () => {
        if (reportValidity()) {
            if (props.onlyAllowCamera) {
                submitForm()
            } else {
                showConfirmationModal()
            }
        }

        return false
    }

    const reportValidity = () => {
        if (!form.value.checkValidity()) {
            form.value.reportValidity()
            return false
        }

        return true
    }
</script>

<template>
    <div ref="confirmationModal" class="modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Bekreft registrering</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-primary">
                        Sesongkortet vil bli sendt til <strong>{{ firstname }}</strong> på e-post <strong>{{ email }}</strong>
                    </div>
                    <div>
                        Vennligst bekreft at du vil registrere kortet?
                        <br>
                        <strong>NB!</strong> Kortet kan ikke endres når det er registrert.
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Avbryt</button>
                    <button @click="submitForm" type="button" class="btn btn-primary">Send inn</button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <form
            ref="form"
            @submit.prevent="onSubmit"
        >
            <div class="row" :class="{ 'mb-0': onlyAllowCamera }">
                <div class="col-12 col-lg-6 col-xl-4 mb-3">
                    <image-upload
                        v-model="image"
                        :disabled="busy"
                        :hide-upload-button="onlyAllowCamera"
                        required
                    />
                </div>

                <div class="col-12 col-lg-6 col-xl-8 mb-3">
                    <div class="row">
                        <div class="col-12 col-xl-6 mb-3">
                            <label for="season-ticket-registration-firstname" class="form-label text-start w-100">Fornavn</label>
                            <input v-model="firstname" name="firstname" type="text" class="form-control" id="season-ticket-registration-firstname" required autocomplete="given-name" :disabled="busy">
                        </div>
                        <div class="col-12 col-xl-6 mb-3">
                            <label for="season-ticket-registration-surname" class="form-label text-start w-100">Etternavn</label>
                            <input v-model="surname" name="surname" type="text" class="form-control" id="season-ticket-registration-surname" required autocomplete="familiy-name" :disabled="busy">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="season-ticket-registration-email" class="form-label text-start w-100">E-post adresse</label>
                        <input v-model="email" name="mail" type="email" class="form-control" id="season-ticket-registration-email" required autocomplete="email" :disabled="busy">
                    </div>

                    <div v-if="!hideAlert" class="mt-3 alert alert-primary">
                        Sesongkortet vil bli sendt til e-postadressen du oppgir.<br>
                        Om du ønsker å sende Sesongkortet som gave, oppgir du mottakers bilde, navn og e-postadresse i skjemaet.
                    </div>

                    <template v-if="!onlyAllowCamera">
                        <button type="submit" class="btn btn-lg btn-primary btn-primary-embossed w-100" :disabled="busy">
                            <template v-if="!busy">
                                Registrer                            
                            </template>
                            <template v-else>
                                <font-awesome-icon icon="spinner" spin />
                            </template>
                        </button>

                        <div v-if="error" class="alert alert-danger mt-3">
                            Det oppstod en feil
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="onlyAllowCamera">
                <hr class="mt-1 mb-3">
                <button type="submit" class="btn btn-lg btn-primary btn-primary-embossed w-100" :disabled="busy">
                    <template v-if="!busy">
                        <font-awesome-icon icon="floppy-disk" /> Lagre
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="spinner" spin />
                    </template>
                </button>

                <a v-if="props.redirect" class="btn btn-lg btn-danger w-100 mt-2" :href="props.redirect">
                    <font-awesome-icon icon="times" /> Avbryt
                </a>

                <div v-if="error" class="alert alert-danger mt-3">
                    Det oppstod en feil
                </div>
            </template>
        </form>
    </div>
</template>