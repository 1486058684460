<script setup>
    import { hide } from '@popperjs/core';
import { ref, onMounted, computed, defineProps, defineEmits, watchEffect } from 'vue'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: {
            type: [String, null],
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        hideUploadButton: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },
    })

    const blank = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs='
    const black = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='

    const image = computed({
        get() {
            return props.modelValue ?? (photoMode.value ? black : blank)
        },

        set(value) {
            emit('update:modelValue', value)

            if (!value && photoMode.value) {
                startCamera()
            }
        }
    })

    const dropContainer = ref(null)
    const audio = ref(null)
    const photoMode = ref(false)
    const camera = ref(null)
    const video = ref(null)
    const loadingCamera = ref(false)
    const photoTaken = ref(false)
    const cameraEnabled = ref(false)
    const cameraNotSupported = ref(false)

    const fileInput = ref(null)
    const dragging = ref(false)
    const dropAllowed = ref(false)

    const openFilePicker = () => {
        fileInput.value.click()
    }

    const startCamera = async () => {
        photoTaken.value = false

        if (!camera.value && !camera.cameraEnabled && !cameraNotSupported.value) {
            try {
                if (!camera.value) {
                    loadingCamera.value = true
                    camera.value = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            facingMode: 'user',
                            aspect: 1,
                        }
                    })

                    loadingCamera.value = false
                }

                if (camera.value) {
                    photoMode.value = true
                    cameraEnabled.value = true
                    video.value.srcObject = camera.value
                    video.value.play()
                } else {
                    photoMode.value = false
                    cameraNotSupported.value = true
                }
            } catch(e) {
                console.error(e)
                photoMode.value = false
                cameraNotSupported.value = true
            }
        }
    }

    const capturePhoto = () => {
        photoTaken.value = true
        audio.value.currentTime = 0
        audio.value.play()
        const canvas = document.createElement('canvas')

        canvas.width = video.value.videoWidth
        canvas.height = video.value.videoHeight

        const ctx = canvas.getContext('2d')
        ctx.drawImage(video.value, 0, 0, video.value.videoWidth, video.value.videoHeight)
        const frame = canvas.toDataURL('image/jpeg')

        if (frame && frame !== 'data:,') {
            image.value = frame
            stopCamera()
        } else {
            setTimeout(() => {
                capturePhoto()
            })
        }
    }

    const cancelPhotoMode = () => {
        stopCamera()
        photoMode.value = false
    }

    const stopCamera = () => {
        video.value.srcObject = null
        video.value.pause()
        cameraEnabled.value = false
        camera.value = false
    }

    const onDragOver = (event) => {
        event.preventDefault()
    }

    const onDragEnter = (event) => {
        event.preventDefault()

        if (dragging.value) {
            return
        }

        dragging.value = true
        
        const [item] = event.dataTransfer.items
            
        if (item && item.kind === 'file' && item.type === 'image/jpeg') {
            dropAllowed.value = true
            return
        }

        dropAllowed.value = false
    }

    const onDragLeave = (event) => {
        event.preventDefault()

        if (event.currentTarget.contains(event.relatedTarget)) {
            return
        }

        if (!dragging.value) {
            return
        }

        dragging.value = false
    }

    const onDrop = (event) => {
        event.preventDefault()
        const [file] = event.dataTransfer.files

        dragging.value = false
        dropAllowed.value = false

        if (file && file.type === 'image/jpeg') {
            const reader = new FileReader
            reader.readAsDataURL(file)
            reader.addEventListener('load', () => {
                image.value = reader.result
            }, false)
        }

        if (cameraEnabled.value) {
            stopCamera()
        }
    }

    onMounted(() => {
        fileInput.value.addEventListener('change', (event) => {
            if (event.target.files[0]) {
                const reader = new FileReader()
                reader.readAsDataURL(event.target.files[0])
                reader.addEventListener('load', () => {
                    image.value = reader.result
                }, false)
            }
        }, false)
    })
</script>

<template>
    <input
        ref="fileInput"
        type="file"
        accept="image/jpeg"
        :disabled="props.disabled"
        class="d-none"
    />

    <audio
        class="d-none"
        ref="audio"
    >
        <source
            :src="'/audio/shutter.mp3'"
            type="audio/mpeg"
        >
    </audio>

    <div>
        <div class="d-flex flex-column">
            <div
                ref="dropContainer"
                @drop.prevent="onDrop"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                class="ImageUpload d-flex flex-column justify-content-center align-items-center position-relative"
                :class="{
                    'flash': photoTaken,
                    'ImageUpload--dragging': dragging,
                    'ImageUpload--drop-forbidden': dragging && !dropAllowed,
                }"
            >
            <div class="ImageUpload__Container border border-1 border-secondary rounded img-fluid w-100 h-100 ratio ratio-1x1">
                <input
                    type="text"
                    class="position-absolute opacity-0 user-select-none"
                    style="pointer-events: none"
                    :required="required"
                    :value="modelValue"
                    :disabled="props.disabled"
                    autocomplete="off"
                />
                <img
                    :src="image"
                    class="ImageUpload__Image rounded"
                    :class="{
                        'd-none': cameraEnabled,
                        'opacity-75': props.disabled,
                        'ImageUpload__Image--PhotoMode': true,
                        'ImageUpload__Image--has-image': modelValue && !props.disabled,
                    }"
                >
                <video
                    ref="video"
                    muted
                    playsinline="true"
                    :class="{ 'd-none': !cameraEnabled }"
                    class="ImageUpload__Video rounded"
                ></video>
            </div>

            <template v-if="!cameraEnabled">
                <div
                    v-if="!modelValue"
                    class="position-absolute text-center mx-5"
                    :class="{ 'd-none': photoMode }"
                >
                    <div class="h2">
                        <font-awesome-icon
                            :icon="['fas', 'image']"
                            :class="{ 'mb-2': !hideUploadButton }"
                        />
                    </div> 
                    <div v-if="!hideUploadButton">
                        Dra ett bilde hit, eller<br><a @click.prevent="openFilePicker" role="button" href="#">velg en fil</a>.
                    </div>
                </div>
                <div v-if="modelValue && !props.disabled" class="ImageUpload__Overlay d-flex justify-content-center align-items-end position-absolute text-center mx-5 w-100 h-100">
                    <button @click.prevent="image = null" class="btn btn-lg btn-danger w-100 rounded-bottom" :disabled="props.disabled">
                        <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="me-2"
                        />
                        Fjern bilde
                    </button> 
                </div>
            </template>
            <template v-else>
                <div class="ImageUpload__Overlay d-flex justify-content-center align-items-end position-absolute text-center mx-5 w-100 h-100">
                    <button @click.prevent="capturePhoto" class="btn btn-lg btn-primary w-100 rounded-bottom" :disabled="props.disabled">
                        <font-awesome-icon
                            :icon="['fas', 'camera']"
                            class="me-2"
                        />
                        Ta bilde
                    </button> 
                </div>
            </template>
        </div>

        <div v-if="cameraNotSupported" class="mt-3 alert alert-danger">
            Kameraet er ikke støttet av nettleseren din, eller du har ikke gitt tillatelse til å bruke kameraet.
        </div>
        
        <div v-if="!cameraNotSupported" :class="{
            disabled: modelValue
        }">
            <div class="d-flex align-items-center gap-3 my-2">
                <div v-if="!hideUploadButton" class="w-100">
                    <div class="text-center">eller</div>
                </div>
            </div>

            <button
                v-if="!cameraEnabled"
                @click.prevent="startCamera"
                :disabled="props.disabled || loadingCamera"
                class="btn btn-primary w-100"
            >
                <template v-if="!loadingCamera">
                    <font-awesome-icon
                        :icon="['fas', 'camera']"
                        class="me-2"
                    />
                    Ta bilde
                </template>
                <template v-else>
                    <font-awesome-icon
                        :icon="['fas', 'spinner']"
                        spin
                    />
                </template>
            </button>

            <button
                v-else
                :disabled="props.disabled"
                @click.prevent="cancelPhotoMode" 
                class="btn btn-danger w-100"
            >
                <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="me-2"
                />
                Avbryt
            </button>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    @keyframes flash {
        0% {
            filter: brightness(100.0);
        }
        100% {
            filter: brightness(1.0);
        }
    }

    .flash {
        animation: flash 0.2s;
    }

    .ImageUpload {
        &__Container {
            border-style: dashed!important;
        }

        &--dragging {
            filter: brightness(0.95);
            cursor: copy;
        }

        &--drop-forbidden {
            filter: brightness(1.0);
            cursor: not-allowed;
        }

        &__Image, &__Video {
            object-fit: cover;

            &--has-image {
                &:hover {
                    filter: brightness(0.95);
                    cursor: pointer;
                }
            }
        }

        &__Image {
            background-color: #FFFFFF;
            &--PhotoMode {
                background-color: #000000;    
            }
        }

        &__Video {
            background-color: #000000;
        }
    }
</style>